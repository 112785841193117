<template>
  <header class="mx-50">
    <div class="modal-catalogo pink-color" v-if="isCatalogoOpen">
      <div class="text-end w-100">
        <img
          src="../assets/img/close-btn-pink.svg"
          alt="chiudi"
          draggable="false"
          @click="closeCatalogo"
        />
      </div>

      <div class="cat-cnt">
        <iframe
          :src="`${publicPath}/contents/${catalogo.catalogo_pdf}#toolbar=0`"
          width="100%"
          height="500px"
        ></iframe>
        <a
          :href="`${publicPath}/contents/${catalogo.catalogo_pdf}`"
          target="_blank"
        >
          <button class="button-border pink-border">SCARICA</button>
        </a>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row 100-w">
        <div class="col-5 p-0 links">
          <a href="/#/lafiaccola">ABOUT</a>
          <a href="/#/lafiaccola/#gallery">GALLERY</a>
          <a href="/#/lafiaccola-news">NEWS</a>
          <a href="/#/" class="black-link">SICILIAPUNTOL</a>
        </div>
        <div class="loghi col-sm-6 col-lg-3 p-0">
          <router-link to="/">
            <img
              src="../assets/img/logo.png"
              draggable="false"
              alt="link SiciliaPuntoL"
            />
          </router-link>

          <router-link to="/lafiaccola">
            <img
              src="../assets/img/fiaccola.png"
              draggable="false"
              alt="link LaFiaccola"
            />
          </router-link>
        </div>
        <div class="col-4 p-0 social text-end">
          <button @click="openCatalogo" class="button-border">CATALOGO</button>
          <div>
            <!-- FACEBOOK -->
            <a
              href="https://it-it.facebook.com/sicilia.libertaria/"
              target="_blank"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10C0 14.84 3.44 18.87 8 19.8V13H6V10H8V7.5C8 5.57 9.57 4 11.5 4H14V7H12C11.45 7 11 7.45 11 8V10H14V13H11V19.95C16.05 19.45 20 15.19 20 10Z"
                  fill="white"
                />
              </svg>
            </a>

            <!-- TELEGRAM -->
            <a href="https://t.me/sicilialibertaria" target="_blank">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14.64 6.8C14.49 8.38 13.84 12.22 13.51 13.99C13.37 14.74 13.09 14.99 12.83 15.02C12.25 15.07 11.81 14.64 11.25 14.27C10.37 13.69 9.87 13.33 9.02 12.77C8.03 12.12 8.67 11.76 9.24 11.18C9.39 11.03 11.95 8.7 12 8.49C12.0069 8.45819 12.006 8.42517 11.9973 8.3938C11.9886 8.36244 11.9724 8.33367 11.95 8.31C11.89 8.26 11.81 8.28 11.74 8.29C11.65 8.31 10.25 9.24 7.52 11.08C7.12 11.35 6.76 11.49 6.44 11.48C6.08 11.47 5.4 11.28 4.89 11.11C4.26 10.91 3.77 10.8 3.81 10.45C3.83 10.27 4.08 10.09 4.55 9.9C7.47 8.63 9.41 7.79 10.38 7.39C13.16 6.23 13.73 6.03 14.11 6.03C14.19 6.03 14.38 6.05 14.5 6.15C14.6 6.23 14.63 6.34 14.64 6.42C14.63 6.48 14.65 6.66 14.64 6.8Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
        <div class="mob-ham col-sm-6 text-end justify-content-between">
          <img
            @click="openMenu"
            src="../assets/img/mob-ham.svg"
            alt="open menu"
            draggable="false"
          />
        </div>
      </div>
    </div>

    <!-- MOBILE MENU -->
    <div class="mobile-menu pink-bg" v-if="isMenuOpen">
      <div class="buttons d-flex">
        <div class="loghi">
          <a href="/#/" @click="closeMenu">
            <img src="../assets/img/logo.png" draggable="false"
          /></a>
          <a @click="closeMenu" href="/#/lafiaccola"
            ><img src="../assets/img/fiaccola.png" draggable="false" />
          </a>
        </div>

        <div class="mob-ham text-end w-100">
          <img
            @click="closeMenu"
            src="../assets/img/mob-ham-close.svg"
            alt="close menu"
            draggable="false"
          />
        </div>
      </div>

      <div class="links-mobile">
        <div>
          <a href="/#/lafiaccola" @click="closeMenu">ABOUT</a>
          <a href="/#/lafiaccola/#gallery" @click="closeMenu">GALLERY</a>
          <a href="/#/lafiaccola-news" @click="closeMenu">NEWS</a>
          <a href="/#/" @click="closeMenu" class="black-link">SICILIAPUNTOL</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderLaFiaccola",

  data() {
    return {
      isMenuOpen: false,
      publicPath: process.env.VUE_APP_PUBLIC_PATH,
      isCatalogoOpen: false,
      catalogo: {},
      apiUrl: process.env.VUE_APP_API_ENDPOINT,
    };
  },

  methods: {
    openMenu() {
      this.isMenuOpen = true;

      if (this.isMenuOpen) {
        document.querySelector("body").style.overflowY = "hidden";
        document.querySelector("body").style.position = "fixed";
        document.querySelector("body").style.top = `-${this.scrollPosition}px`;
        document.querySelector("body").style.width = "100%";
      }
    },
    closeMenu() {
      this.isMenuOpen = false;

      if (this.isMenuOpen === false) {
        document.querySelector("body").style.overflowY = "scroll";
        document.querySelector("body").style.removeProperty("position");
        document.querySelector("body").style.removeProperty("top");
        document.querySelector("body").style.removeProperty("width");
      }
    },
    openCatalogo() {
      this.isCatalogoOpen = true;
    },

    closeCatalogo() {
      this.isCatalogoOpen = false;
    },
  },

  async mounted() {
    let data = await fetch(
      `${this.apiUrl}/cataloghi?categoria=LaFiaccola`
    );
    let catalogo = await data.json();
    this.catalogo = catalogo[0];
  },
};
</script>


