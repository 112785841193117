<template>
  <section id="gallery" class="gallery gallery-fiaccola">
    <!-- <<<< MODAL >>>> -->
    <Transition>
      <div class="book-opened-modal" id="modalOver" v-if="BookOpen.title">
        <div class="col-sm-12 col-lg-6">
          <img
            @click="closeBook"
            v-if="BookOpen.title"
            class="close-btn-mob"
            src="../assets/img/close-btn-pink.svg"
            alt="close"
            draggable="false"
          />
          <carousel :perPage="1">
            <slide>
              <img
                :src="`${publicPath}/contents/${BookOpen.photo}`"
                draggable="false"
              />
            </slide>
            <slide v-if="BookOpen.photo2">
              <img
                :src="`${publicPath}/contents/${BookOpen.photo2}`"
                draggable="false"
              />
            </slide>
            <slide v-if="BookOpen.photo3">
              <img
                :src="`${publicPath}/contents/${BookOpen.photo3}`"
                draggable="false"
              />
            </slide>
          </carousel>
        </div>
        <div class="col-sm-12 col-lg-6">
          <img
            @click="closeBook"
            v-if="BookOpen.title"
            class="close-btn-desk"
            src="../assets/img/close-btn-pink.svg"
            alt="close"
            draggable="false"
          />
          <p class="news-subtitle">
            {{ "Collana " + BookOpen.collana }}, <br />
            <span v-if="BookOpen.anno_edizione"
              >{{ "Ed. " + BookOpen.anno_edizione }} <br
            /></span>
            {{ "pp. " + BookOpen.numero_pagine }}, {{ "€ " + BookOpen.prezzo }}
            <br />
            <span v-if="BookOpen.codice_isbn">{{
              "ISBN " + BookOpen.codice_isbn
            }}</span>
          </p>

          <h2 class="news-title">
            {{ BookOpen.title }}
          </h2>
          <p class="news-subtitle" v-if="BookOpen.prefazione_a_cura_di">
            {{ "Prefazione a cura di " + BookOpen.prefazione_a_cura_di }}
          </p>
          <p class="news-desc" v-html="BookOpen.descrizione"></p>
        </div>
      </div>
    </Transition>
    <div class="container-fluid">
      <!-- <<<< TITLE + FILTERS >>>> -->
      <div class="row mx-50 title-wrapper">
        <div class="col-sm-12 col-lg-3 p-0">
          <h1 class="section-title pink-color">NOVITÀ</h1>
        </div>
        <!--         <div class="col-sm-12 col-lg-9 p-0 filters-cnt pink-color">
          <button
            class="filter-btn"
            @click="isAuthorMenuOpen = !isAuthorMenuOpen"
          >
            AUTORE
            <svg
              class="filter-btn-arrow"
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L5 5L1 9" stroke="#CC2E63" />
            </svg>
            <div class="menu-tendina pink-color" v-if="isAuthorMenuOpen">
              <ul>
                <li @click="mostraTutti">Tutti</li>
                <li
                  @click="
                    filterAuthor(autore.autore_cognome, autore.autore_nome)
                  "
                  v-for="(autore, index) in autori"
                  :key="index"
                >
                  {{ autore.autore_cognome + " " + autore.autore_nome }}
                </li>
              </ul>
            </div>
          </button>

          <button class="filter-btn">
            GENERE
            <svg
              class="filter-btn-arrow"
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L5 5L1 9" stroke="#CC2E63" />
            </svg>
          </button>
        </div> -->
      </div>
      <!-- <<<< BOOKS GALLERY >>>> -->
      <div class="row mx-50 books-gallery hide-mobile">
        <div class="col-sm-0 col-lg-3 p-0 title-cnt"></div>
        <div class="col-sm-12 col-lg-9 p-0 books-cnt">
          <div class="books">
            <div
              class="col-lg-4 new-book"
              v-for="(libro, index) in libri.slice(0, 3)"
              :key="index"
              @click="openBook(libro)"
            >
              <img
                :src="`${publicPath}/contents/${libro.photo}`"
                alt="cover libro"
              />
              <p>{{ libro.autore_nome + " " + libro.autore_cognome }}</p>
              <h2>{{ libro.title }}</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-50 show-mobile books-gallery-mobile">
        <carousel :perPage="1" class="p-0" @input="updateCurrentSlideIndex">
          <slide
            v-for="(libro, index) in libri.slice(0, 3)"
            :key="index"
            class="gallery-slide"
          >
            <div class="col-6">
              <img
                :src="`${publicPath}/contents/${libro.photo}`"
                alt="cover libro"
              />
            </div>
            <div class="col-6">
              <div class="texts">
                <p class="news-subtitle">
                  {{ libro.autore_nome + " " + libro.autore_cognome }}
                </p>
                <h2 class="news-title">{{ libro.title }}</h2>
              </div>

              <button class="button-border" @click="openBook(libro)">
                LEGGI DI PIÙ
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L5 5L1 9" stroke="#CC2E63" />
                </svg>
              </button>
            </div>
          </slide>
          <div slot="pagination" class="pagination-carousel pink-color">
            {{ currentSlideIndex + 1 }}/3
          </div>
        </carousel>
      </div>

      <div class="row mx-50 title-wrapper">
        <div class="col-sm-12 col-lg-3 p-0">
          <h1 class="section-title pink-color mt-5">DAL<br />CATALOGO</h1>
        </div>
      </div>

      <div class="row mx-50 books-gallery hide-mobile">
        <div class="col-sm-0 col-lg-3 p-0 title-cnt"></div>
        <div class="col-sm-12 col-lg-9 p-0 books-cnt">
          <div class="books">
            <div
              class="col-lg-4 new-book"
              v-for="(libro, index) in libri.slice(3)"
              :key="index"
              @click="openBook(libro)"
            >
              <img
                :src="`${publicPath}/contents/${libro.photo}`"
                alt="cover libro"
              />
              <p>{{ libro.autore_nome + " " + libro.autore_cognome }}</p>
              <h2>{{ libro.title }}</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-50 show-mobile books-gallery-mobile">
        <carousel :perPage="1" class="p-0" @input="updateCurrentSlideIndex">
          <slide
            v-for="(libro, index) in libri.slice(3)"
            :key="index"
            class="gallery-slide"
          >
            <div class="col-6">
              <img
                :src="`${publicPath}/contents/${libro.photo}`"
                alt="cover libro"
              />
            </div>
            <div class="col-6">
              <div class="texts">
                <p class="news-subtitle">
                  {{ libro.autore_nome + " " + libro.autore_cognome }}
                </p>
                <h2 class="news-title">{{ libro.title }}</h2>
              </div>

              <button class="button-border" @click="openBook(libro)">
                LEGGI DI PIÙ
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L5 5L1 9" stroke="#CC2E63" />
                </svg>
              </button>
            </div>
          </slide>
          <div slot="pagination" class="pagination-carousel pink-color">
            {{ currentSlideIndex + 1 }}/{{ libri.slice(3).length }}
          </div>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "GalleryLaFiaccola",

  data() {
    return {
      libri: [],
      autori: [],
      BookOpen: {},
      originalLibri: [],
      isAuthorMenuOpen: false,
      currentSlideIndex: 0,
      apiUrl: process.env.VUE_APP_API_ENDPOINT,
      publicPath: process.env.VUE_APP_PUBLIC_PATH,
    };
  },

  components: {
    Carousel,
    Slide,
  },

  methods: {
    openBook(libro) {
      this.BookOpen = libro;
      document.querySelector("html").style.overflowY = "hidden";
      this.isAuthorMenuOpen = false;
    },
    closeBook() {
      this.BookOpen = false;
      document.querySelector("html").style.overflowY = "scroll";
    },
    filterAuthor(autore_cognome, autore_nome) {
      this.libri = this.originalLibri.filter(
        (el) =>
          el.autore_cognome === autore_cognome && el.autore_nome === autore_nome
      );
    },
    mostraTutti() {
      this.libri = this.originalLibri;
    },
    updateCurrentSlideIndex(index) {
      this.currentSlideIndex = index;
    },
  },

  async mounted() {
    let data = await fetch(
      `${this.apiUrl}/libri?categoria=lafiaccola`
    );
    let libri = await data.json();

    let autori_data = await fetch(
      `${this.apiUrl}/autori?categoria=lafiaccola`
    );
    let autori = await autori_data.json();

    this.libri = libri;
    this.originalLibri = libri;
    this.autori = autori;
  },
};
</script>
